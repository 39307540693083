import { Input, InputProps, Space } from "antd";
import "./code.less";

export interface CodeInputProps {
  length?: number;
}
const DEFAULT_LENGTH = 4;
export const Code = (props: InputProps & CodeInputProps) => {
  const inputs = [...Array(props.length ?? DEFAULT_LENGTH)].map(() => (
    <Input
      {...props}
      className={`${props.className} code`}
      maxLength={1}
      defaultValue="0"
    />
  ));
  return <Space.Compact>{inputs}</Space.Compact>;
};
