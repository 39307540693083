import * as THREE from "three";
import { Object3D } from "three";

export const createCube = () => {
  const geometry = new THREE.BoxGeometry(1, 1, 1);
  const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
  const cube = new THREE.Mesh(geometry, material);

  return { cube };
};
export const createScene = (conf: { width: number; height: number }) => {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    75,
    conf.width / conf.height,
    0.1,
    1000
  );
  const renderer = new THREE.WebGLRenderer({ alpha: true });
  renderer.setSize(conf.width, conf.height);
  return { scene, camera, renderer };
};

export const useAnimate = (renderer: THREE.WebGLRenderer) => {
  return (
      callback: any, //FrameRequestCallback
      object?: Object3D
    ) =>
    (scene: THREE.Scene, camera: THREE.PerspectiveCamera) => {
      requestAnimationFrame(callback);
      //   callback();
      renderer.render(scene, camera);
    };
};
