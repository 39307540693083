const actions = {
  get_db_request: () => ({ type: "GET_DB_REQUEST" }),
  get_db_success: (db: any) => ({ type: "GET_DB_SUCCESS", payload: db }),
  add: ({ id, number = 1 }: any) => ({
    type: "ADD_REQUEST",
    payload: { id, number },
  }),
  extract: ({ id, number = 1 }: any) => ({
    type: "EXTRACT_REQUEST",
    payload: { id, number },
  }),
  add_success: (payload: any) => ({ type: "ADD_SUCCESS", payload }),
  extract_success: (payload: any) => ({ type: "EXTRACT_SUCCESS", payload }),
};
export default actions;
