import {
  CameraControls,
  Environment,
  Html,
  PerspectiveCamera,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Loader } from "components/Loader/Loader";
import { PropsWithChildren, Suspense, useEffect, useRef } from "react";

export const MyScene = (props: PropsWithChildren<any>) => {
  return (
    <Canvas>
      <Suspense
        fallback={
          <Html>
            <Loader />
          </Html>
        }
      >
        {props.manual && <ManualCamera />}
        {!props.manual && <CameraControls />}
        {props.children}
        <Environment preset="sunset" />
        {/* <Sky /> */}
      </Suspense>
    </Canvas>
  );
};

const ManualCamera = () => {
  const cameraRef = useRef<any>(null);

  useEffect(() => {
    cameraRef.current?.lookAt(-3, 0, 0);
  }, []);

  return (
    <PerspectiveCamera
      ref={cameraRef}
      fov={75}
      makeDefault
      position={[-3, -2, -5]}
    ></PerspectiveCamera>
  );
};
