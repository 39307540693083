import { useState, useEffect } from "react";
import { CODEX } from "utils/wheels";

export function useKeyboardEvent() {
  const [text, setText] = useState<string | null>(null);

  const handleKeydown = (event: KeyboardEvent) => {
    if (CODEX.alphanum.includes(event.key)) {
      setText(text ? text + event.key : event.key);
    } else if (event.key === "Backspace" && text?.length) {
      setText(text?.slice(0, text.length - 1));
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);

    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  return text;
}
