import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Form, Input, Space } from "antd";
import { DestructuredLogo } from "components/DestructuredLogo/DestructuredLogo";
import { Code } from "components/Inputs/Code/Code";
import { Previous } from "components/Previous/Previous";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextTransition from "react-text-transition";
import "./admin.less";
import actions from "./services/actions";

export const Admin = (props: any) => {
  const states = useSelector((store: any) => store.adminStore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [movement, setMovement] = useState("initial");
  const [email, setEmail] = useState<string | null>();
  const [formRef] = Form.useForm();
  const [emailInputIcon, setEmailInputIcon] = useState<any>(null);

  const onFinish = (event: { email: string; code?: string }) => {
    if (!states.codeReceived && event.code) dispatch(actions.code(event.code));
    else {
      dispatch(actions.email(event.email));
    }
  };

  const cancelEmail = () => {
    dispatch(actions.cancel());
    setMovement("initial");
    formRef.setFieldValue("email", null);
    setEmailInputIcon(null);
  };

  useEffect(() => {
    states.loading && setEmailInputIcon(<SyncOutlined spin />);
  }, [states.loading]);

  useEffect(() => {
    if (states.validEmail) {
      setEmailInputIcon(<CheckCircleOutlined />);
      setMovement("destroy");
    }
  }, [states.validEmail]);

  return (
    <>
      <DestructuredLogo loading={states.loading} movement={movement} />

      <Form form={formRef} onFinish={onFinish} style={{ width: "200px" }}>
        <Space direction="vertical">
          {states.codeReceived && <Previous onClick={cancelEmail} />}
          <b>
            <TextTransition>{states.title}</TextTransition>
          </b>
          <Form.Item name="email">
            <Input
              disabled={states.codeReceived || states.loading}
              // onFocus={() => setMovement("destroy")}
              // onBlur={() => setMovement("initial")}
              className={`glass-input`}
              suffix={emailInputIcon}
            />
          </Form.Item>
          <Form.Item name="code" hidden={!states.codeReceived}>
            <Code className={`glass-input`}></Code>
          </Form.Item>
        </Space>
        <button type="submit" hidden></button>
      </Form>
    </>
  );
};
