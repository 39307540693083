import { Segment, Segments } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useState } from "react";
export const Direction = () => {
  //   const ref = useRef<Mesh>(null);
  const [endPosition, setEndPosition] = useState<[number, number, number]>([
    0, 0, 0,
  ]);
  const { pointer } = useThree();
  useFrame(() => {
    setEndPosition([pointer.x, pointer.y, 0]);
  });
  //   useFrame(() => {
  //     if (!ref.current) return
  //     ref.position.x
  //   });
  //   window.addEventListener("mousemove", (event: MouseEvent) => {
  //     console.log([event.clientX, event.clientY, 0]);
  //     setEndPosition([event.clientX, event.clientY, 0]);
  //   });
  return (
    <Segments>
      <Segment start={[0, 0, 0]} end={endPosition} />
    </Segments>
  );
};
