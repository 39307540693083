import { useEffect } from "react";
import { useDrag } from "react-dnd";

export const Item = (props: {
  x?: number;
  y?: number;
  color: string;
  onDragging?: () => void;
  onClick?: () => void;
}) => {
  const [isDragging, dragRef] = useDrag(() => ({
    type: "color",
    item: { color: props.color },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    if (isDragging && props.onDragging) props.onDragging();
  }, [isDragging]);
  return (
    <div
      onClick={props.onClick}
      ref={dragRef}
      className={`${props.color} item`}
    ></div>
  );
};
