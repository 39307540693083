import { Page } from "components/Contents";
import { ChoiceButton } from "components/Html";
import { Scroller } from "components/Scroller/Scroller";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import "./../../meytvassa.less";
import { Cover } from "./Cover";
import { FirstParagraph, SecondParagraph, ThirdParagraph } from "./Paragraph";
interface PrequelProps {
  onFinish: () => void;
}

export const Prequel = (props: PrequelProps) => {
  return (
    <div className="bg-black" style={{ width: "100vw", overflowX: "hidden" }}>
      <Scroller>
        {/* Cover 2 pages */}
        <Cover page={0} />
        <FirstParagraph page={2} />
        <SecondParagraph page={3} />
        <ThirdParagraph page={4} />
        <WelcomeChoice page={5} />
      </Scroller>
    </div>
  );
};

export const WelcomeChoice = (props: any) => {
  const [id] = useState(uuid());
  const navigate = useNavigate();
  return (
    <Page {...props}>
      <div className="flex-center">
        <ChoiceButton
          url={"./images/paroles_de_melya.jpeg"}
          onClick={() => navigate(`/meytvassa/${id}/riff`)}
        >
          Aller voir les Paroles de Mëlya
        </ChoiceButton>
        <ChoiceButton
          url={"./images/riff.jpeg"}
          onClick={() => navigate(`/meytvassa/${id}/riff`)}
        >
          Accompagner les Mëlandals sur les côtes
        </ChoiceButton>
      </div>
    </Page>
  );
};
