import {
  CameraControls,
  Environment,
  Html,
  Loader,
  PerspectiveCamera,
  Scroll,
  ScrollControls,
  Sky,
  Text,
  useAnimations,
  useGLTF,
  useScroll,
  useSpriteAnimator,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { getRandomColor } from "utils/maths";

export const Animation = () => {
  return (
    <section className="main-container" style={{ height: "100vh" }}>
      <Canvas>
        <Suspense
          fallback={
            <Html>
              <Loader />
            </Html>
          }
        >
          <Camera />
          {/* <Spiderman /> */}
          <Lubidere />

          <Environment preset="sunset" />
          <Sky />
        </Suspense>
      </Canvas>
    </section>
  );
};
export const Camera = () => {
  const cameraRef = useRef<any>(null);
  const [cameraPosition, setCameraPosition] = useState({
    x: 0,
    y: 0,
    z: 0,
  });

  return (
    <>
      <group>
        <Text>
          [x : {cameraPosition.x}] [y : {cameraPosition.y}] [z :
          {cameraPosition.z}]
        </Text>
      </group>
      <CameraControls ref={cameraRef}>
        <PerspectiveCamera makeDefault position={[8, 5, 10]} />
      </CameraControls>
    </>
  );
};

export const Lubidere = () => {
  const lubidere = useGLTF("./3Dmodels/lubidere/scene.glb");

  return <primitive scale={0.5} object={lubidere.scene}></primitive>;
};

export const Spiderman = () => {
  const spiderman = useGLTF("./3Dmodels/beule/scene.glb");
  const { ref, mixer, names, actions, clips } = useAnimations(
    spiderman.animations
  );

  useEffect(() => {
    actions[names[0]]?.reset().fadeIn(0.5).play();
    return () => {
      actions[names[0]]?.fadeOut(0.5);
    };
  }, [actions]);
  return <primitive ref={ref} scale={0.5} object={spiderman.scene}></primitive>;
};
export const ScrollController = () => {
  return (
    <ScrollControls pages={3} damping={0.3}>
      <Scroll html>
        {/* DOM contents in here will scroll along */}
        <div
          className="main-container"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="flex-center flex-column">
            <h1 className="title-section">Animation</h1>
          </div>
        </div>
        <div
          className="main-container"
          style={{ backgroundColor: getRandomColor() }}
        >
          <h1>html in here (optional)</h1>
        </div>
      </Scroll>
      <Scroll>
        {/* Canvas contents in here will scroll along */}
        <Foo position={[0, 0, 0]} />
        <Foo position={[0, window.innerHeight, 0]} />
        <Foo position={[0, window.innerHeight * 1, 0]} />
      </Scroll>
      <Spiderman />
    </ScrollControls>
  );
};
function Foo(props: any) {
  const ref = useRef();
  const data = useScroll();
  useFrame(() => {
    // data.offset = current scroll position, between 0 and 1, dampened
    // data.delta = current delta, between 0 and 1, dampened

    // Will be 0 when the scrollbar is at the starting position,
    // then increase to 1 until 1 / 3 of the scroll distance is reached
    const a = data.range(0, 1 / 3);
    // Will start increasing when 1 / 3 of the scroll distance is reached,
    // and reach 1 when it reaches 2 / 3rds.
    const b = data.range(1 / 3, 1 / 3);
    // Same as above but with a margin of 0.1 on both ends
    const c = data.range(1 / 3, 1 / 3, 0.1);
    // Will move between 0-1-0 for the selected range
    const d = data.curve(1 / 3, 1 / 3);
    // Same as above, but with a margin of 0.1 on both ends
    const e = data.curve(1 / 3, 1 / 3, 0.1);
    // Returns true if the offset is in range and false if it isn't
    const f = data.visible(2 / 3, 1 / 3);
    // The visible function can also receive a margin
    const g = data.visible(2 / 3, 1 / 3, 0.1);
  });
  return <mesh ref={ref} {...props} />;
}
function FireScroll() {
  const sprite = useSpriteAnimator();
  const scroll = useScroll();
  const ref = React.useRef();
  useFrame(() => {
    if (sprite && scroll) {
      sprite.current = scroll.offset;
    }
  });

  return null;
}
