import { memo } from "react";
import { useDrop } from "react-dnd";
import "./colors.less";
export const Square = memo(
  (props: {
    x: number;
    y: number;
    accept?: string;
    onDrop: (item: any) => void;
    onClick?: (x: number, y: number) => void;
    children?: JSX.Element;
    childrenLength?: number;
  }) => {
    const [{ isOver, canDrop }, dropRef] = useDrop(
      () => ({
        accept: ["color"],
        drop: props.onDrop,
        canDrop: (item) => {
          return props.childrenLength ? props.childrenLength < 2 : true;
        },
        collect: (monitor: any) => ({
          isOver: !!monitor?.isOver(),
          canDrop: !!monitor?.canDrop(),
        }),
      }),
      [props.accept, props.childrenLength]
    );

    return (
      <div
        ref={dropRef}
        className={`box ${isOver && "overed"} ${!canDrop && "unauthorized"}`}
        onClick={() => props?.onClick && props.onClick(props.x, props.y)}
      >
        {props.children}

        {props.accept && (
          <div className="accepts">
            <span
              title={props.accept}
              className={`${props.accept}-accept`}
            ></span>
          </div>
        )}
      </div>
    );
  }
);
