import anime from "animejs";
import { Page } from "components/Contents";
import { H2 } from "components/Html";
import {
  relativeToPageIndex,
  ScrollerContext,
} from "components/Scroller/Scroller";
import { SvgMeytvassa } from "components/Svg";
import { useContext, useEffect, useState } from "react";
import { ScrollStatus } from "smooth-scrollbar/interfaces";

export const Cover = (props: any) => {
  const [page] = useState(props.page || 0);
  return (
    <Page
      overflow={"hidden"}
      layer
      animeBackground
      parallax={1.4}
      scale
      url="./images/forest.jpeg"
      combinePages={2}
    >
      <div className="flex-column">
        <FrontPage page={page} />
        <TitlePage page={page + 1} />
      </div>
    </Page>
  );
};

export const FrontPage = (props: any) => {
  const { initScroller } = useContext(ScrollerContext);

  const handleAnime = (e: ScrollStatus) => {
    const scrollYPagePosition = relativeToPageIndex(e, props.page);

    if (scrollYPagePosition === 0) {
      anime({
        targets: "#main-title",
        translateY: [100, 0],
        easing: "easeInOutExpo",
        opacity: [0, 1],
        duration: 2000,
      });
    }
    if (scrollYPagePosition <= window.innerHeight) {
      anime({
        targets: "#main-title",
        translateY: [0, scrollYPagePosition * 0.9],
        easing: "linear",
        duration: 0,
      });
    }
  };

  useEffect(() => {
    const scroller = initScroller();
    anime({
      targets: "#main-title",
      translateY: [100, 0],
      easing: "easeInOutExpo",
      opacity: [0, 1],
      duration: 2000,
    });
    scroller && scroller.on("scroll", handleAnime);
  }, []);
  return (
    <Page color="transparent">
      <div className="flex-center">
        <div id="main-title">
          <SvgMeytvassa />
        </div>
      </div>
    </Page>
  );
};

export const TitlePage = (props: any) => {
  const { initScroller } = useContext(ScrollerContext);

  const handleAnime = (e: ScrollStatus) => {
    const scrollYPagePosition = relativeToPageIndex(e, props.page);
    if (scrollYPagePosition < 0) return;
    if (scrollYPagePosition > window.innerHeight / 3) {
      anime({
        targets: "#prequel-title",
        translateY: scrollYPagePosition - window.innerHeight / 3,
        easing: "linear",
        duration: 0,
      });
    }
  };

  useEffect(() => {
    const scroller = initScroller();
    scroller && scroller.on("scroll", handleAnime);
  }, []);

  return (
    <Page color="transparent">
      <div className="flex-center">
        <H2 id="prequel-title" shadow>
          La naissance de l'Ours
        </H2>
      </div>
    </Page>
  );
};
