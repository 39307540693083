import { useEffect, useRef, useState } from "react";
import { createCube, createScene } from "./hooks";
import "./three.less";

const conf = {
  width: window.innerWidth,
  height: window.innerHeight,
};
const { scene, camera, renderer } = createScene(conf);
const { cube } = createCube();
export const Three = () => {
  //   const animate = useAnimate(renderer);
  const [cameraPosition, setCameraPosition] = useState(10);
  const [animationFrame, setAnimationFrame] = useState<number | null>(null);
  const [userMouse, setUserMouse] = useState({
    x: conf.width / 2,
    y: conf.height / 2,
  });
  const ref = useRef<HTMLDivElement>(null);

  function animation() {
    console.log("animationframe", animationFrame);
    animationFrame && cancelAnimationFrame(animationFrame);
    setAnimationFrame(requestAnimationFrame(animation));
    //   cube.position.x = userMouse.x;
    //   cube.position.y = userMouse.y;
    camera.position.z = cameraPosition;
    // console.log("camera", camera.position.z);
    console.log("anuimation camera position");

    renderer.render(scene, camera);
  }
  useEffect(() => {
    animation();
  }, [cameraPosition]);

  useEffect(() => {
    scene.add(cube);
    console.log("cube", cube.position);
    if (ref.current) ref.current.appendChild(renderer.domElement);
    animation();
  }, []);

  window.addEventListener("mousemove", (event: MouseEvent) => {
    //   console.log("mousemove", event);
    // console.log({ x: event.clientX, y: event.clientY });
    setUserMouse({ x: event.clientX, y: event.clientY });
  });

  window.addEventListener("wheel", (event: WheelEvent) => {
    //   console.log("onWheel", event.deltaY);
    let add = event.deltaY > 0 ? 1 : -1;

    setCameraPosition(camera.position.z + add);
  });
  //   const onWheel = (event: React.WheelEvent & any) => {
  //     const anim = (nb: number) => {
  //       cube.rotation.x += nb;
  //     };
  //     setCameraPosition(cameraPosition + event.deltaY);

  //   };

  return (
    <div id="scroll-area">
      <div className="main-container" ref={ref}></div>
    </div>
  );
};
