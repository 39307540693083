import { Loader } from "components/Loader/Loader";
import { Admin } from "pages/Admin/Admin";
import { Agario } from "pages/Agario/Agario";
import { Animation } from "pages/Animation/Animation";
import { Colors } from "pages/Colors/Colors";
import { Home } from "pages/Home/Home";
import { Meytvassa } from "pages/Meytvassa/Meytvassa";
import { Riff } from "pages/Meytvassa/Steps/Riff/Riff";
import { Three } from "pages/Three/Three";
import { Navigate } from "react-router-dom";
import { getRandomInt } from "utils/maths";
import App from "./App";
const password = [...Array(6)].map(() => getRandomInt(9)).join("");
export const ROUTES = [
  {
    path: "/welcome/:password",
    name: "Welcome",
    element: <App />,
  },
  {
    path: "/welcome",
    type: "redirect",
    element: <Navigate to={`/welcome/${password}`} />,
  },
  {
    path: "/admin",
    name: "Admin",
    element: <Admin />,
  },
  {
    path: "/colors",
    name: "Colors",
    element: <Colors />,
  },
  {
    path: "/home",
    name: "Home",
    element: <Home />,
  },
  {
    path: "/three",
    name: "Three",
    element: <Three />,
  },
  {
    path: "/agario",
    name: "Agario",
    element: <Agario />,
  },
  {
    path: "/animation",
    name: "Animation",
    standalone: true,
    element: <Animation />,
  },
  {
    path: "/meytvassa",
    name: "Meytvassa",
    standalone: true,
    element: <Meytvassa />,
  },
  {
    path: "/meytvassa/:id/riff",
    name: "riff",
    standalone: true,
    element: <Riff />,
  },

  {
    path: "/loader",
    name: "Loader",
    element: <Loader />,
  },
];
