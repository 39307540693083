import { combineReducers } from "@reduxjs/toolkit";
import { adminStore } from "pages/Admin/services/redux";
import { colorStore } from "pages/Colors/services/redux";
import { homeStore } from "pages/Home/services/redux";

export default combineReducers({
  adminStore,
  colorStore,
  homeStore,
});
