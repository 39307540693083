import anime from "animejs";
import { MenuButton } from "components/Buttons/MenuButton/MenuButton";
import { ROUTES } from "core/routes";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./menu.less";
export const Menu = () => {
  const [open, setOpen] = useState(false);
  const [navigating, setNavigating] = useState(false);
  const navigate = useNavigate();

  const onNavigating = (to: string) => {
    setNavigating(true);
    setTimeout(() => {
      navigate(to);
    }, 400);
    setTimeout(() => {
      setNavigating(false);
      setOpen(false);
    }, 800);
  };

  const handleAnimeEnter = (linkName: string) => {
    anime({
      targets: `.${linkName}`,
      translateX: 5,
      direction: "normal",
    });
  };

  const handleAnimeLeave = (linkName: string) => {
    anime({
      targets: `.${linkName}`,
      translateX: -5,
      direction: "normal",
    });
  };

  useEffect(() => {
    anime({
      targets: ".link",
      translateX: -5,
      direction: "normal",
      delay: function (el, i, l) {
        return i * 50;
      },
    });
  }, [open]);

  return (
    <div
      id="main-menu"
      className={`glassmorphism ${navigating ? "transitioning" : ""} ${
        open ? "open" : "close"
      }`}
    >
      {!navigating && (
        <div className="icon-container">
          <MenuButton
            onClick={() => setOpen(!open)}
            opened={open}
            className={`menu-icon ${open ? "open" : "close"}`}
          >
            {!open ? "Menu" : "Close"}
          </MenuButton>
        </div>
      )}
      {open ? (
        <div className="menu-container">
          {ROUTES.map(
            (route) =>
              route.name && (
                <Link
                  className={`link ${route.name}`}
                  to="#"
                  onClick={() => onNavigating(route.path)}
                  onMouseEnter={() => handleAnimeEnter(route.name)}
                  onMouseLeave={() => handleAnimeLeave(route.name)}
                >
                  {route.name}
                </Link>
              )
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
