import { useAnimations, useGLTF } from "@react-three/drei";
import { useEffect } from "react";

export const Flag3D = () => {
  const flag = useGLTF("./3Dmodels/flag/scene.glb");
  const { ref, mixer, names, actions, clips } = useAnimations(
    flag.animations,
    flag.scene
  );

  useEffect(() => {
    clips.forEach((clip: any) => {
      mixer.clipAction(clip).play();
    });
  }, [clips]);
  return <primitive object={flag.scene}></primitive>;
};
