import { CharWheel } from "components";
import { useKeyboardEvent } from "hooks/useKeyboardEvent";
import { useEffect, useState } from "react";
import { CodeWheelsProps } from "types/props";
import { getRandomInt } from "utils/maths";
import {
  CODEX,
  howManyWheelsToReach,
  onWheelDown,
  onWheelUp,
} from "utils/wheels";
import "./codeWheels.less";
export const CodeWheels = (props: CodeWheelsProps): JSX.Element => {
  const [codex, setCodex] = useState(CODEX["alpha"]);
  const _codeIndex = props.code.split("").map((char) => codex.indexOf(char));
  const [hackCode, setHackCode] = useState(
    _codeIndex.map((char) => onWheelDown(char, props.type))
  );
  const [randomWheel, setRandomWheel] = useState({
    action: "down",
    wheels: getRandomInt(codex.length) - 1,
  });

  const [disabled, setDisabled] = useState(props.disabled);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAction, setCurrentAction] = useState<"up" | "down">("down");

  const keyboardEvent = useKeyboardEvent();

  useEffect(() => {
    if (keyboardEvent && !disabled) {
      const char = keyboardEvent[keyboardEvent.length - 1];
      const { action, wheels } = howManyWheelsToReach(
        codeBeingHacked(hackCode)[keyboardEvent.length - 1],
        char
      );
      action === "down" && handleWheelDown(keyboardEvent.length - 1);
      action === "up" && handleWheelUp(keyboardEvent.length - 1);
      setRandomWheel({ action, wheels: wheels - 1 });
    }
  }, [keyboardEvent]);

  useEffect(() => {
    setHackCode(_codeIndex.map((char) => onWheelDown(char, props.type)));
  }, [props.code]);

  useEffect(() => {
    // test
    // if (props.randomStart) setDisabled(true);
    // else
    setDisabled(props.disabled);
  }, [props.disabled]);

  useEffect(() => {
    if (props.randomStartLoop) {
      setRandomWheel({ action: "down", wheels: props.randomStartLoop });
    } else {
      setRandomWheel({
        action: "down",
        wheels: getRandomInt(codex.length) - 1,
      });
    }
  }, [props.randomStartLoop]);

  useEffect(() => {
    setCodex(props.type ? CODEX[props.type] : CODEX["alpha"]);
  }, [props.type]);

  useEffect(() => {
    if (props.randomStart && randomWheel.wheels >= 0) {
      setRandomWheel({
        action: randomWheel.action,
        wheels: randomWheel.wheels - 1,
      });
      setDisabled(randomWheel.wheels > 0 || props.disabled);
      if (randomWheel.wheels > 0) {
        randomWheel.action === "down" &&
          setTimeout(() => handleWheelDown(0), 100);
        randomWheel.action === "up" && setTimeout(() => handleWheelUp(0), 100);
      }
    }
  }, [hackCode]);

  const codeBeingHacked = (newHack: number[]) => {
    // permet de parvenir au code avec les index
    return newHack.map((i: number) => codex[i]).join("");
  };
  const checkCode = (newHack: number[]) => {
    props.onChange(codeBeingHacked(newHack));
    if (codeBeingHacked(newHack) === props.code) {
      props.onSolved();
      setRandomWheel({ action: "down", wheels: 0 });
      setHackCode(_codeIndex);
    } else {
      setHackCode(newHack);
    }
  };

  const handleWheelUp = (index: number) => {
    const newHack =
      props.wheelAll || (props.randomStart && randomWheel.wheels > 0)
        ? hackCode.map((char) => onWheelUp(char, props.type))
        : hackCode.map((char, i) =>
            index === i ? onWheelUp(char, props.type) : char
          );
    setCurrentIndex(index);
    setCurrentAction("up");
    checkCode(newHack);
  };
  // faire ici une gestion Wheel automatique
  const handleWheelDown = (index: number) => {
    const newHack =
      props.wheelAll || (props.randomStart && randomWheel.wheels > 0)
        ? hackCode.map((char) => onWheelDown(char, props.type))
        : hackCode.map((char, i) =>
            index === i ? onWheelDown(char, props.type) : char
          );
    setCurrentIndex(index);
    setCurrentAction("down");
    checkCode(newHack);
  };

  const wheels = hackCode.map((charIndex, i: number) => {
    const current = i === currentIndex;
    const delay = current ? 0 : i * 100;
    const direction = current ? currentAction : i % 2 === 0 ? "up" : "down";
    return (
      <CharWheel
        key={i}
        disabled={disabled}
        muffler={props.muffler}
        direction={direction}
        delay={delay}
        color={charIndex % 9}
        current={current}
        char={codex[charIndex]}
        solved={charIndex === _codeIndex[i]}
        lock={
          props.lockCharAfterSolved
            ? charIndex === _codeIndex[i]
            : codeBeingHacked(hackCode) === props.code
        }
        onWheelUp={() => {
          handleWheelUp(i);
        }}
        onWheelDown={() => {
          handleWheelDown(i);
        }}
      />
    );
  });

  return <div className="code-container flex-row">{wheels}</div>;
};
