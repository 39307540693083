import unlockSound from "assets/unlock.wav";
import vaultSound from "assets/vault.wav";
import vaultHackedSound from "assets/vault_hacked.wav";
import { concat } from "lodash";
export const vaultNoise = () => {
  let vault = new Audio(vaultSound);
  vault.volume = 0.05;
  // vault.play();
};
export const vaultHackedNoise = () => {
  let vault = new Audio(vaultHackedSound);
  vault.volume = 0.1;
  // vault.play();
};
export const unlockNoise = () => {
  let vault = new Audio(unlockSound);
  vault.volume = 0.1;
  // vault.play();
};

const alpha_codex = "abcdefghijklmnopqrstuvwxyz".split("");
const numeric_codex = "0123456789".split("");
export const CODEX = {
  alpha: alpha_codex,
  numeric: numeric_codex,
  alphanum: concat(alpha_codex, numeric_codex),
};
export const onWheelUp = (
  index: number,
  type: "alpha" | "numeric" | "alphanum" = "alpha"
): number => {
  if (index >= CODEX[type].length - 1) return 0;
  else return index + 1;
};

export const onWheelDown = (
  index: number,
  type: "alpha" | "numeric" | "alphanum" = "alpha"
): number => {
  if (index <= 0) return CODEX[type].length - 1;
  else return index - 1;
};

export const howManyWheelsToReach = (
  start: string,
  to: string,
  type: "alpha" | "numeric" | "alphanum" = "alpha"
) => {
  let indexOfStart = CODEX[type].indexOf(start);
  let indexOfTo = CODEX[type].indexOf(to);

  const distance = indexOfStart - indexOfTo;
  if (distance < 0)
    return {
      action: "up",
      wheels: -distance,
    };
  else
    return {
      action: "down",
      wheels: distance,
    };
};
