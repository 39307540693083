import {
  CameraControls,
  Plane,
  Sphere as DSphere,
  SpotLight,
  Text,
} from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useRef, useState } from "react";
import * as THREE from "three";
import { getRandomColor, getRandomInt, round } from "utils/maths";
export const Sphere = (props: any) => {
  const ref = useRef<THREE.Mesh>(null);
  const feedRef = useRef<THREE.Mesh>(null);
  const cameraRef = useRef<any>(null);
  const { pointer } = useThree();
  const spotLightRef = useRef<any>(null);
  const boardRef = useRef<THREE.Mesh>(null);
  const [feed, setFeed] = useState<any>({
    color: getRandomColor(),
    position: [1, 1, 0],
    args: [0.4],
  });

  const [radius, setRadius] = useState<any>(1);

  const checkCollision = (ref: any, ref2: any, border: any) => {
    const distX = Math.abs(ref.position.x - ref2.position.x);
    const distY = Math.abs(ref.position.y - ref2.position.y);
    if (
      distX <
        ref.geometry.parameters.radius - ref2.geometry.parameters.radius &&
      distY < ref.geometry.parameters.radius - ref2.geometry.parameters.radius
    ) {
      setRadius(round(radius + ref2.geometry.parameters.radius / radius));

      const newFeedX =
        (ref.position.x + getRandomInt(10)) * (getRandomInt(2) ? -1 : 1);
      const newFeedY =
        ref.position.y + getRandomInt(10) * (getRandomInt(2) ? -1 : 1);
      setFeed({
        color: getRandomColor(),
        position: [
          border.scale.x / 2 <= Math.abs(newFeedX)
            ? border.scale.x / 2 - 1
            : newFeedX,
          border.scale.y / 2 <= Math.abs(newFeedY)
            ? border.scale.y / 2 - 1
            : newFeedY,
          0,
        ],
        args: [0.4],
      });
    }
  };
  const checkBorder = (userBall: any, border: any) => {
    if (
      userBall.position.x + pointer.x > 0 &&
      userBall.position.x + pointer.x + userBall.geometry.parameters.radius >=
        border.scale.x / 2
    ) {
    } else if (
      userBall.position.x + pointer.x - userBall.geometry.parameters.radius <=
      -(border.scale.x / 2)
    ) {
    } else {
      userBall.position.x += pointer.x / 5;
    }

    if (
      userBall.position.y + pointer.y > 0 &&
      userBall.position.y + pointer.y + userBall.geometry.parameters.radius >=
        border.scale.y / 2
    ) {
      // right
    } else if (
      userBall.position.y + pointer.y - userBall.geometry.parameters.radius <=
      -(border.scale.y / 2)
    ) {
    } else {
      userBall.position.y += pointer.y / 5;
    }
    cameraRef.current.moveTo(userBall.position.x, userBall.position.y, 10);
  };
  useFrame(() => {
    if (!ref.current || !cameraRef.current) return;

    if (feedRef.current && boardRef.current)
      checkCollision(ref.current, feedRef.current, boardRef.current);
    if (boardRef.current) checkBorder(ref.current, boardRef.current);

    if (spotLightRef.current) {
      spotLightRef.current.target = ref.current;
    }
  });

  return (
    <>
      <SpotLight
        ref={spotLightRef}
        distance={10}
        angle={1}
        attenuation={5}
        position={[2, 2, 5]}
        anglePower={5}
      />
      <Plane ref={boardRef} scale={30} material-color="white"></Plane>
      <DSphere ref={ref} args={[radius]} position={[0, 0, 0]}>
        <meshPhysicalMaterial color="#f3f3f3" />
        <Text anchorX={"center"} anchorY={radius} color="white">
          {radius}
        </Text>
      </DSphere>
      <CameraControls ref={cameraRef} makeDefault />

      <DSphere ref={feedRef} {...feed} material-color={feed.color} />
    </>
  );
};
