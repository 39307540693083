import { PayloadAction } from "@reduxjs/toolkit";
import { addAccepts, addMix, checkPosition } from "./utils";

const ITEMS = [
  { color: "red", x: 0, y: 0 },
  { color: "blue", x: 1, y: 1 },
  { color: "yellow", x: 2, y: 2 },
];

const ACCEPTS = [{ cell_x: 3, cell_y: 2, accept: "purple" }];

const initial_state = {
  itemsPosition: ITEMS as any[],
  col: 4,
  row: 4,
  accepts: ACCEPTS,
  solved: false,
  solution: [
    {
      color: "purple",
      cell_x: 3,
      cell_y: 2,
      solved: false,
    },
    {
      color: "orange",
      cell_x: 3,
      cell_y: 0,
      solved: false,
    },
    {
      color: "yellow",
      cell_x: 0,
      cell_y: 3,
      solved: false,
    },
    {
      color: "green",
      cell_x: 3,
      cell_y: 1,
      solved: false,
    },
    {
      color: "red",
      cell_x: 2,
      cell_y: 0,
      solved: false,
    },
  ],
};

interface ItemPosition {
  color: string;
  x: number;
  y: number;
}
export const colorStore = (
  state = initial_state,
  action: PayloadAction<ItemPosition>
) => {
  switch (action.type) {
    case "COLOR_ADD":
      return {
        ...state,
        itemsPosition: [...state.itemsPosition, action.payload],
      };
    case "COLOR_MOVE":
      const newItemsPosition = addMix(
        state.itemsPosition.map((item) =>
          item.color === action.payload.color ? action.payload : item
        )
      );
      const check = checkPosition(
        state.accepts.find(
          (acceptance) =>
            acceptance.cell_x === action.payload.x &&
            acceptance.cell_y === action.payload.y
        ),
        newItemsPosition.filter(
          (item) => item.x === action.payload.x && item.y === action.payload.y
        )
      );

      let newSolution = check
        ? state.solution.map((sol) =>
            sol.color === check ? { ...sol, solved: true } : sol
          )
        : state.solution;

      const newAccepts =
        check && addAccepts(newSolution)
          ? [...state.accepts, addAccepts(newSolution)]
          : state.accepts;

      const solved = newItemsPosition
        .map((item) => {
          const solution = state.solution.find(
            ({ color }) => color === item.color
          );
          if (solution) {
            return solution.cell_x === item.x && solution.cell_y === item.y;
          } else {
            return true;
          }
        })
        .reduce((incr, cur) => incr && cur);

      return {
        ...state,
        itemsPosition: newItemsPosition,
        solution: newSolution,
        accepts: newAccepts,
        solved,
      };

    default:
      return state;
  }
};
