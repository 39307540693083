import { useState } from "react";
import "./meytvassa.less";
import { Prequel } from "./Steps/Prequel/Prequel";

export const Meytvassa = () => {
  const [step, setStep] = useState(0);

  return (
    <section id="meytvassa-history">
      <Prequel onFinish={() => setStep(step + 1)} />
    </section>
  );
};
