import { Action } from "redux";

const initial_state = {
  loading: false,
  success: false,
  codeReceived: false,
  validEmail: false,
  title: "Enter your email",
};
export const adminStore = (state = initial_state, action: Action) => {
  switch (action.type) {
    case "ADMIN_EMAIL_REQUEST":
      return { ...state, loading: true };
    case "ADMIN_CODE_REQUEST":
      return { ...state, loading: true };
    case "ADMIN_EMAIL_SUCCESS":
      return {
        ...state,
        title: "Enter the code",
        loading: false,
        validEmail: true,
        codeReceived: true,
      };
    case "ADMIN_CANCEL_REQUEST":
      return {
        ...initial_state,
      };
    default:
      return state;
  }
};
