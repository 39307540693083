import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "styles/index.less";
import reportWebVitals from "./reportWebVitals";

import createSagaMiddleware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";
import { ConfigProvider, theme } from "antd";
import router from "core/routing";
import rootSagas from "core/sagas";
import appStore from "core/store";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";

const sagaMiddleware = createSagaMiddleware() as any;
const store = configureStore({
  reducer: appStore,
  middleware: (gDM) => gDM().concat(sagaMiddleware),
});
sagaMiddleware.run(rootSagas);

createRoot(document.getElementById("root") as any).render(
  <StrictMode>
    <DndProvider backend={HTML5Backend}>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: "#60a4eb",
          },
        }}
      >
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </ConfigProvider>
    </DndProvider>
  </StrictMode>
);

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(<React.StrictMode></React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
