import { Canvas } from "@react-three/fiber";
import "./agario.less";
import { Direction } from "./Direction";
import { Sphere } from "./Sphere";
export const Agario = () => {
  return (
    <Canvas className="canvas">
      <Sphere />

      <Direction />
    </Canvas>
  );
};
