import React, { useEffect, useRef, useState } from "react";
import TextTransition, {
  presets,
  TextTransitionProps,
} from "react-text-transition";
import { vaultHackedNoise, vaultNoise } from "utils/wheels";
import { CharwheelProps } from "../../types/props";
import "./charWheel.less";

export const CharWheel = (
  props: CharwheelProps & TextTransitionProps
): JSX.Element => {
  useEffect(() => {}, []);
  const refResult = useRef<HTMLDivElement>(null);
  const [disabledDelay, setDisabledDelay] = useState(props.disabled);
  const [mobileTouchScreenY, setMobileTouchScreenY] = useState(0);
  const onWheel = (event: React.WheelEvent & any) => {
    if (props.disabled || props.lock) return;
    if (event.deltaY > 0) props.onWheelUp();
    if (event.deltaY < 0) props.onWheelDown();
  };

  const onTouchMove = (event: React.TouchEvent) => {
    if (props.disabled || props.lock) return;
    if (!mobileTouchScreenY) {
      setMobileTouchScreenY(event.targetTouches[0].screenY);
      return;
    }
    const moveY = mobileTouchScreenY - event.targetTouches[0].screenY;
    if (moveY < 10 && moveY > -10) return;
    if (moveY > 0) props.onWheelUp();
    if (moveY < 0) props.onWheelDown();
    setMobileTouchScreenY(event.targetTouches[0].screenY);
  };

  useEffect(() => {
    if (props.lock) {
      if (refResult.current) refResult.current.innerHTML = props.char;
    } else if (refResult.current) refResult.current.innerHTML = "";
  }, [props.lock]);

  useEffect(() => {
    if (!props.muffler && props.current) {
      vaultNoise();
    }
  }, [props.char]);

  useEffect(() => {
    if (props.solved && props.current && !props.muffler) vaultHackedNoise();
  }, [props.solved]);
  useEffect(() => {
    if (props.disabled) {
      setTimeout(() => {
        setDisabledDelay(props.disabled);
      }, props.delay || 0 + 100);
    } else {
      setDisabledDelay(props.disabled);
    }
  }, [props.disabled]);

  return (
    <div className="flex-column wheel">
      <TextTransition
        className={`char-size ${disabledDelay && "disabled"}`}
        direction={props.direction}
        springConfig={presets.wobbly}
        delay={props.delay}
      >
        {props.char}
      </TextTransition>
      <div
        ref={refResult}
        onWheel={onWheel}
        onTouchMove={onTouchMove}
        className={`wheel-box result-box ${props.lock && "disabled"} color-${
          props.color
        }`}
      ></div>
    </div>
  );
};
