import { isEqual, uniqWith } from "lodash";
import { ItemPosition } from "types/props";
export const get = (array: ItemPosition[], color: string) => {
  return array.find((item) => item.color === color);
};

export const hasSamePosition = (array: ItemPosition[], colors: string[]) => {
  const uniq = uniqWith(
    array
      .filter(({ color }) => colors.includes(color))
      .map(({ x, y }) => ({ x, y })),
    isEqual
  );
  return uniq.length === 1;
};

// export const addRandomAccept = (array: ItemPosition[], filter: string) => {
//   const random_color = array
//     .map(({ color }) => color)
//     .filter((item) => item !== filter)[getRandomInt(array.length - 1)];
//   const accepts = {
//     accepts: [random_color],
//     cell_x: getRandomInt(state.col),
//     cell_y: getRandomInt(state.row),
//   };
//   return accepts;
// };
export enum COLOR {
  PURPLE = "purple",
}
export const addAccepts = (solution: any) => {
  const next = solution.find(
    ({ solved }: { solved: boolean }) => solved === false
  );
  if (!next) return null;
  return {
    accept: next.color,
    cell_y: next.cell_y,
    cell_x: next.cell_x,
  };
};
export const checkPosition = (
  acceptance: any,
  itemsPosition: ItemPosition[]
): string | false => {
  if (
    acceptance &&
    itemsPosition.map(({ color }) => color).includes(acceptance.accept)
  ) {
    return acceptance.accept;
  } else {
    return false;
  }
};

export const addMix = (array: ItemPosition[]) => {
  const addMix = [...array];
  if (!get(array, "green") && hasSamePosition(array, ["yellow", "blue"])) {
    addMix.push({
      color: "green",
      x: get(array, "yellow")?.x as number,
      y: get(array, "yellow")?.y as number,
    });
  }
  if (!get(array, "purple") && hasSamePosition(array, ["red", "blue"])) {
    addMix.push({
      color: "purple",
      x: get(array, "red")?.x as number,
      y: get(array, "red")?.y as number,
    });
  }
  if (!get(array, "orange") && hasSamePosition(array, ["red", "yellow"])) {
    addMix.push({
      color: "orange",
      x: get(array, "red")?.x as number,
      y: get(array, "red")?.y as number,
    });
  }
  return addMix;
};
