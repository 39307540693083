import { ButtonProps } from "antd";
import { omit } from "lodash";
import { useEffect, useState } from "react";
import "./menuButton.less";
export const MenuButton = (props: ButtonProps & { opened: boolean }) => {
  const [initialize, setInitialize] = useState(true);

  useEffect(() => {
    setTimeout(() => setInitialize(false), 100);
  }, []);
  return (
    <div className="menu-wrapper">
      {/* <div className="text">Menu</div> */}
      <div id="button" {...omit(props, ["opened"])}>
        <div
          className={`icon ${props.opened ? "open" : "close"} ${
            initialize ? "initializing" : ""
          } `}
        >
          <span className="vector"></span>
          <span className="vector"></span>
          <span className="vector"></span>
        </div>
      </div>
    </div>
  );
};
