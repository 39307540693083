import anime from "animejs";
import { Page } from "components/Contents";
import { H1 } from "components/Html";
import { Scroller, ScrollerContext } from "components/Scroller/Scroller";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FreehandMap } from "../Prequel/Ornament";
import "./../../meytvassa.less";

export const Riff = (props: any) => {
  const { id } = useParams();
  return (
    <div className="bg-black" style={{ width: "100vw", overflowX: "hidden" }}>
      <Scroller>
        <div style={{ height: "100vh" }}>
          {/* Cover 2 pages */}
          <RiffTitle page={0} />
          <Page
            page={1}
            overflow={"hidden"}
            layer
            backgroundLayer="linear-gradient(0deg, transparent 0%, #010719 100%)"
            animeBackground
            parallax={1.2}
            url="/images/map_0.jpg"
          >
            <div>
              <FreehandMap anime />
            </div>
          </Page>

          <Page page={2} />
        </div>
      </Scroller>
    </div>
  );
};

const RiffTitle = (props: any) => {
  const { initScroller } = useContext(ScrollerContext);

  useEffect(() => {
    anime({
      targets: "#main-title",
      translateY: [100, 0],
      easing: "easeInOutExpo",
      opacity: [0, 1],
      duration: 2000,
    });
    // const scroller = initScroller();
    // scroller && scroller.on("scroll", () => {});
  }, []);

  return (
    <Page
      overflow={"hidden"}
      layer
      backgroundLayer="linear-gradient(0deg, transparent 0%, #010719 100%)"
      animeBackground
      parallax={0.2}
      url="/images/riff.jpeg"
    >
      <div className="flex-center">
        <H1 shadow url="/images/riff.jpeg" id="main-title">
          Les côtes d'Artémis
        </H1>
      </div>
    </Page>
  );
};
