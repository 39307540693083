import { Button, Space } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextTransition from "react-text-transition";
import "./home.less";
import actions from "./services/actions";
const Action = (props: any) => {
  return (
    <>
      {props.loading ? (
        <Button loading={props.loading} disabled={props.loading} />
      ) : (
        <Space.Compact>
          <Button onClick={props.extract}>-</Button>
          <Button onClick={props.add}>+</Button>
        </Space.Compact>
      )}
    </>
  );
};
export const Home = (props: any) => {
  const dispatch = useDispatch();
  const state = useSelector((store: any) => store.homeStore);

  useEffect(() => {
    dispatch(actions.get_db_request());
  }, []);

  return (
    <>
      <div className="flex-row">
        {state?.us?.map((people: any, key: number) => (
          <div key={key} className="account flex-column flex-center">
            <h1>{people.name}</h1>
            <div className="tresor flex-center">
              <span className="tresor-count flex-center">
                <TextTransition>{people.tresor}</TextTransition>
              </span>
            </div>
            <Action
              loading={state.loading}
              add={() => dispatch(actions.add({ id: people.id }))}
              extract={() => dispatch(actions.extract({ id: people.id }))}
            />
          </div>
        ))}
      </div>
    </>
  );
};
