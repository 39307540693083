import anime from "animejs";
import { useEffect, useRef } from "react";
import "./loader.less";
export const Loader = () => {
  const zRef = useRef<SVGGeometryElement>(null);
  const zedRef = useRef<SVGSVGElement>(null);
  useEffect(() => {
    anime({
      targets: ".line-zed",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      delay: function (el, i) {
        return i * 250;
      },
      direction: "alternate",
      loop: true,
    });
  }, []);
  return (
    <div className="flex-center">
      <div style={{ position: "relative" }}>
        <svg
          ref={zedRef}
          id="zed"
          width="180"
          height="220"
          viewBox="0 80 180 220"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            ref={zRef}
            className="line-zed"
            d="M3.49996 293.523L3.49996 297.023L6.99996 297.023L176.31 297.023L179.81 297.023L179.81 293.523L179.81 270.183L179.81 266.683L176.31 266.683L50.1476 266.683L176.292 106.201L177.041 105.249L177.041 104.038L177.041 85.8413L177.041 82.3413L173.541 82.3413L9.3735 82.3413L5.8735 82.3413L5.8735 85.8413L5.8735 109.181L5.8735 112.681L9.3735 112.681L130.727 112.681L4.25367 272.76L3.49997 273.714L3.49997 274.93L3.49996 293.523Z"
            stroke="white"
            strokeWidth="7"
          />
        </svg>
      </div>
    </div>
  );
};
