import { omit } from "lodash";
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react";
import "../html.less";

export const H1 = (
  props: PropsWithChildren<
    DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
  > & { url?: string; shadow?: boolean }
) => {
  return (
    <div style={{ position: "relative" }} id={props.id}>
      <h1
        {...omit(props, ["children", "id"])}
        className={`${props.className ?? ""} shadow ${
          !props.shadow ? "no-shadow" : ""
        }`}
      >
        {props.children}
      </h1>
      <h1
        {...omit(props, ["children", "id"])}
        className={`${props.className}`}
        style={{
          backgroundImage: `url("${props.url}")`,
          backgroundClip: "text",
        }}
      >
        {props.children}
      </h1>
    </div>
  );
};

export const H2 = (
  props: PropsWithChildren<
    DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
  > & { url?: string; shadow?: boolean }
) => {
  return (
    <div id={props.id}>
      <h2 style={{ color: props.color }} {...omit(props, ["children", "id"])}>
        {props.children}
      </h2>
    </div>
  );
  return (
    <div style={{ position: "relative" }} id={props.id}>
      {
        <h2
          {...omit(props, ["children", "id"])}
          className={`${props.className ?? ""} ui-h2 shadow ${
            !props.shadow ? "no-shadow" : ""
          }`}
        >
          {props.children}
        </h2>
      }
      <h2
        {...omit(props, ["children", "id"])}
        className={`${props.className} ui-h2`}
        style={{
          backgroundImage: `url("${props.url}")`,
          backgroundClip: "text",
        }}
      >
        {props.children}
      </h2>
    </div>
  );
};
