import { call, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";

async function getConfigurationApi(): Promise<any> {
  return await fetch("/api/configuration", {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((e) => console.error("et merde", e));
}
function* adminEmailRequest(): any {
  const response = yield call(getConfigurationApi);
  yield put(actions.email_success());
}
export function* adminSaga() {
  yield takeLatest("ADMIN_EMAIL_REQUEST", adminEmailRequest);
}
