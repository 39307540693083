import { PayloadAction } from "@reduxjs/toolkit";

const initial_state = {
  loading: false,
  success: false,
  us: [],
};
export const homeStore = (
  state = initial_state,
  action: PayloadAction<{ us: [] }>
) => {
  switch (action.type) {
    case "ADD_REQUEST":
    case "GET_DB_REQUEST":
    case "EXTRACT_REQUEST":
      return { ...state, loading: true };
    case "ADD_SUCCESS":
    case "EXTRACT_SUCCESS":
    case "GET_DB_SUCCESS":
      return {
        ...state,
        us: action.payload.us,
        loading: false,
      };

    default:
      return state;
  }
};
